@import 'variables';
@import './button';
@import './dropdown';
@import './form';
@import './menu';
@import './modal';
@import './statistics';
@import './table';

.ui {
    &.segment {
        background-color: $segment-background;
        padding-left: 30px;
        padding-right: 30px;
    }
    &.header {
        color: $light;
    }
    i.circular.icon {
        box-shadow: 0 0 0 0.1em $grey inset;
        &:before {
            color: $white;
        }
    }
    .inverted {
        color: $white !important;
    }
    // &.list {
    //     background-color: $grey;
    // }
}