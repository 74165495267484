@import '../../../../style/variables';

.add-edit-employee-modal {
    .form-field {
        display: inline-block;
        margin-right: 10px;
    }

    label {
        display: block;
        color: $white;
    }
}