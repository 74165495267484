.ui.button {
    &.primary {
        font-family: $fontFamily;
        background-color: $themeColor;
        color: $white;
        &:active, &:hover, &:focus:hover {
            font-family: $fontFamily;
            background-color: $marker;
            color: $white;
        }
        &:focus {
            font-family: $fontFamily;
            background-color: $themeColor;
            color: $white;
        }
    }
    &:disabled {
        background-color: $grey;
    }
}
