@import 'variables';

$background: $theme;
$activeItem: $marker;

.ui.menu {
    background: $background;
    border-top: 0;
    border-radius: 0;
    &.pointing {
        .item {
            cursor: pointer;
            font-family: $fontFamily;
            color: $lightFontColor;

            &.active {
                background: $activeItem;
                color: $lightFontColor;
                &:hover {
                    background: $activeItem;
                    color: $lightFontColor;
                    &:after {
                        background-color: $activeItem;
                        border-color: $activeItem;
                    }
                }
                &:after {
                    background-color: $activeItem;
                    border-color: $activeItem;
                }
            }

            &:hover {
                background: $activeItem;
                color: $lightFontColor;
            }
        }
    }
    .ui.dropdown {
        .menu {
            border: 1px solid $light;
            background-color: $dark;
            .item {
                color: $white !important;
                &:after {
                    visibility: hidden;
                }
                &:hover {
                    background-color: $marker !important;
                    color: $white !important;
                }
                &.selected {
                    color: $light !important;
                }
            }
        }
    }    
}
