@import '../../../style/variables';

footer {
    background-color: $footerBackground;
    text-align: center;
    font-size: 0.8em;
    div {
        color: $lightFontColor;
        padding: 5px;
        margin-top: -30px;
        &:first-of-type {
            padding: 0;
            line-height: $footer-height;
            margin-top: 0;
        }
    }
}
