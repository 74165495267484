@import '../style/variables';

.TimeTracker {
    * {
        box-sizing: border-box;
    }

    .TimeTracker-header {
        background-color: #000;
        color: $lightFontColor;
        height: calc(#{$header-height});
        overflow: hidden;

        h1 {
            position: relative;
            margin: 25px 0;
            display: inline-block;
            font-size: 1.4em;
            font-weight: 500;
            z-index: 2;
        }
        p {
            float: right;
            padding: 20px;
        }
        .TimeTracker-logo {
            float: left;
            height: calc(#{$header-height});
            margin: 0;
            opacity: 1;
            transform: rotate(45deg);
        }
    }

    .TimeTracker-body {
        min-height: calc(100vh - #{$header-height} - #{$footer-height});

        article {
            background-color: $bodyBackground;
            padding: 20px;
            max-width: 1024px;
            margin: auto;
        }
    }

    .TimeTracker-link {
        color: #09d3ac;
    }

    .pointer {
        cursor: pointer;
    }

    .button-link {
        background: none;
        padding: 0;
        border: none;
        color: $light !important;
        cursor: pointer;
    }

    .primary {
        color: $light;
    }
}
