.ui.dropdown {
    i.icon:before {
        color: $dropdown-border !important;
    }
    i.delete.icon {
        &:before {
            color: $black !important;
        }
    }
    &.selection {
        color: $white;
        background-color: transparent !important;
        border: 1px solid $dropdown-border;
        &.active,
        &:hover,
        &.active:hover {
            background-color: transparent !important;
            border: 1px solid $dropdown-border;
        }
        .menu,
        &.active .menu,
        &.active:hover .menu,
        &.active .menu:hover {
            .item {
                color: $white;
                border: none;
                &:hover {
                    background-color: $marker;
                }
            }
            background-color: $dark;
            border-color: $dropdown-border;
        }
    }
}
