// @import '../node_modules/semantic-ui-css/semantic.min.css';
@import './style/variables';
@import './style/overrides';
@import './style/fonts';

body {
    background-color: $bodyBackground;
    color: $white;
    margin: 0;
    font-family: $fontFamily;

    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    //     'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    //     'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input {
    font-family: $fontFamily !important;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: $fontFamily;
    color: $headerFontColor;
}

// Overrides


