@import './themes/bert';

$header-height: 60px;
$footer-height: 60px;

$themeColor: $theme;
$backgroundColor: $bg;

// Font
$fontFamily: 'Ubuntu Mono', courier, arial, sans-serif;
$lightFontColor: $white;
$headerFontColor: $light;

// Layout
$bodyBackground: $backgroundColor;
$footerBackground: $backgroundColor;


// Dropdown
$dropdown-border: $light;

// Segment
$segment-background: $dark;
