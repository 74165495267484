@import 'variables';

.ui.table {
    &.selectable.inverted {
        th {
            color: $light !important;
        }
        .selected {
            background-color: $marker;
            &:hover {
                background-color: $marker !important;
            }
        }
    }
}
