@import '../../style/variables';

#employees-page {
    .list {
        border: 1px solid $light;
        border-radius: 15px;
        padding-top: 10px;
        padding-bottom: 10px;

        .item {
            padding-left: 10px;
            padding-right: 10px;
            &:hover {
                cursor: pointer;
                background-color: $marker;
            }
            &:first-child {
                padding-top: 0.43em;
            }
            &:last-child {
                padding-bottom: 0.43em;
            }
            &.selected {
                background-color: $dark;
            }
        }
    }
}
