@import '../../style/variables';

.StaffingsList {
    margin-top: 1em;
    table {
        td {
            i {
                color: $grey !important;
                padding-left: 10px;
            }
        }
    }
}