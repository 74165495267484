.LoginPage {
    font-size: 0.8em;
    background-color: #000;
    img {
        position: absolute;
        width: 100vw;
        height: 100vh;
    }
    #content {
        button {
            background: none !important;
            border: none;
            padding: 0 !important;
            color: #eee;
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            text-transform: uppercase;
            cursor: pointer;
        }

        position: absolute;
        width: calc(100vw - 100px);
        height: calc(100vh - 100px);
        top: 50px;
        left: 50px;
        color: rgba(225, 225, 255, 0.7);
        font-family: 'Montserrat', sans-serif;
        text-align: right;

        .pbd {
            position: absolute;
            bottom: 0;
            width: 400px;
            text-align: left;
            h1 {
                text-transform: uppercase;
                font-family: 'Montserrat', sans-serif;
                font-weight: 100;
                color: rgba(225, 225, 255, 0.7);
            }
        }
    }
}
