.ui.statistics {
    .statistic {
        border: 3px solid $theme;
        border-radius: 10px;
        padding: 20px;
        margin: 20px !important;
        &.inverted {
            .value {
                & > i:first-child {
                    display: block;
                    color: $light;
                    margin-bottom: 10px;;
                }
                color: $white;
            }
            .label {
                color: $light;
                margin-top: 5px;
            }
        }
    }
}
