.staffing-form {
    label {
        color: #fff;
    }
    .ui.input {
        border: 1px solid #999;
        .icon {
            color: #fff;
            opacity: 1;
        }
        input {
            background-color: transparent;
            color: #fff;
        }
    }
    .selection.dropdown {
        color: #fff;
        background-color: transparent;
        &:not(.active) {
            border: 1px solid #999;
        }
        .dropdown.icon {
            color: #fff;
        }
        .text:not(.default) {
            color: #fff;
        }
        .menu {
            .item {
                color: #ccc;
                background-color: rgba(0, 0, 0, 0.85);
                border-top: 0;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.9);
                }
            }
        }
    }
}
