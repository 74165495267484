@import 'variables';

.ui.modal {
    &.basic {
        border: 2px solid $light;
        border-radius: 10px;
        input {
            background: transparent;
            border: 1px solid $theme;
            color: $white;
            &:active, &:focus {
                background: transparent;
                border: 1px solid $light;
                color: $white;
            }
        }
    }
}
