@import '../../../style/variables';

.confirm-modal {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.85);
    &>div {
        position: fixed;
        top: 50%;
        left: 50%;
        /* bring your own prefixes */
        transform: translate(-50%, -50%);
        width: 30%;
        min-width: 350px;
        height: 30%;
        border: 1px solid $light;
        padding: 20px;
    }
    .header {
        font-size: 1.3em;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .content {
        height: calc(100% - 65px);
    }
    .actions {
        bottom: 0;
        text-align: right;
        height: 50px;
    }
}